import { ApolloProvider } from '@apollo/client'
import { useRouter } from 'next/router'
import { DefaultSeo } from 'next-seo'
import * as Sentry from '@sentry/node'
// TODO: You could conditinally import this only if
// it is needed.
import 'intersection-observer'
//
import '../styles/base.css'

import getGlobalSettings from 'helpers/getGlobalSettings'
import getSites from 'helpers/getSites'
import GlobalContextProvider from 'context/GlobalContextProvider'
import SitesContextProvider from 'context/SitesContextProvider'
import withApollo from 'lib/withApollo'
import SEOSelect from 'next-seo-select.config'
import SEOModellink from 'next-seo-modellink.config'

import Header from 'components/Header'
import isModellinkSite from '../helpers/isModellinkSite'

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    // enabled: process.env.NODE_ENV === 'production',
    enabled: true, // Disabling for now as javascript screws over Sentry quotas
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    environment: process.env.NODE_ENV, // Possibly not the best env to use
  })
}

function App({ Component, pageProps, apollo }) {
  const router = useRouter()

  const disableHeaderPaths = ['/', '/404']
  // router.pathname !== '/' && !router.pathname.startsWith()
  // const noHeaderPaths = ['/', '/404']

  const SEO = isModellinkSite() ? SEOModellink : SEOSelect

  return (
    <ApolloProvider client={apollo}>
      <DefaultSeo {...SEO} />
      <GlobalContextProvider>
        <SitesContextProvider
          siteSlug={router.query.site}
          sites={pageProps.sites}
        >
          {!disableHeaderPaths.find(
            (p) =>
              (p.startsWith && router.pathname.startsWith(p.startsWith)) ||
              p === router.pathname
          ) && <Header globalSettings={pageProps.globalSettings} />}
          <Component {...pageProps} globalSettings={pageProps.globalSettings} />
        </SitesContextProvider>
      </GlobalContextProvider>
    </ApolloProvider>
  )
}

App.getInitialProps = async ({ ctx }) => {
  // const gs = await getGlobalSettings(ctx.query.site);
  // console.log({mainMenu: gs.mainMenu.acfMenuItems.menu[0].items });
  //
  return {
    pageProps: {
      globalSettings: await getGlobalSettings(ctx.query.site),
      sites: await getSites(),
    },
  }
}

export default withApollo(App)
