import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import classNames from 'classnames'

import s from './SelectField.module.css'

class SelectField extends React.Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.any).isRequired,
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func,
    className: PropTypes.string,
    hasError: PropTypes.bool,
    active: PropTypes.bool,
    inputClassName: PropTypes.string,
  }

  static defaultProps = {
    label: null,
    value: '',
    onChange: () => {},
    className: '',
    hasError: false,
    active: null,
    inputClassName: null,
  }

  constructor(props) {
    super(props)
    this.state = {
      optionsDisplayed: false,
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.active === false) {
      return {optionsDisplayed: false}
    }
    return null;
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick)
  }

  getLabel = (value) => {
    if (!value) return this.props.label
    if (typeof this.props.options[0] === 'string') return value
    let selected = null
    this.props.options.forEach((o) => {
      if (Array.isArray(o)) {
        o.forEach((subOpt) => {
          if (subOpt.value === value) selected = subOpt
        })
      }
      if (o.value === value) selected = o
    })
    return selected ? selected.label : value
  }

  formatLabel = (label) => {
    if (!this.props.labelFormatter) return label
    return this.props.labelFormatter(label)
  }

  optionSelected = (value) => {
    this.props.onChange(this.props.name, value)
    this.toggleOptions(false)
  }

  handleClick = (e) => {
    if (!this.container.contains(e.target)) this.toggleOptions(false)
  }

  toggleOptions = (state = null) => {
    this.setState(
      {
        optionsDisplayed: state || !this.state.optionsDisplayed,
      },
      () => {
        if (this.state.optionsDisplayed)
          document.addEventListener('click', this.handleClick)
        else document.removeEventListener('click', this.handleClick)
      }
    )
  }

  renderOption = (option, className = null) => {
    if (Array.isArray(option)) {
      return (
        <Fragment key={option[0]}>
          <li className={classNames(s.option, className, s.optionDisabled)}>
            {option[0]}
          </li>
          {option.slice(1).map((opt) => this.renderOption(opt, s.subOption))}
        </Fragment>
      )
    }
    const value = option.value || option
    return (
      <li
        key={value}
        className={classNames(
          s.option,
          {
            [s.optionSelected]: value === this.props.value,
          },
          {
            [s.optionDisabled]: option.disabled,
          },
          className
        )}
        onClick={option.disabled ? null : () => this.optionSelected(value)}
      >
        {option.label ? option.label : option}
      </li>
    )
  }

  render() {
    const {
      active,
      name,
      label,
      value,
      className,
      options,
      hasError,
      inputClassName,
      optionsClassName,
      optionClassName,
    } = this.props
    const { optionsDisplayed } = this.state
    return (
      <div
        className={classNames(s.container, className, {
          [s.hasError]: active && hasError,
          [s.hasValue]: value,
          [s.hasOptionsDisplayer]: optionsDisplayed,
        })}
        ref={(n) => {
          this.container = n
        }}
      >
        {/* <div className={s.inputContainer}> */}
        <button
          className={classNames(s.input, inputClassName)}
          onClick={() => this.toggleOptions()}
          type="button"
        >
          {this.formatLabel(this.getLabel(value) || '\u00A0')}
        </button>
        {optionsDisplayed && (
          <ul className={classNames(s.options, optionsClassName)}>
            {options.map((option) =>
              this.renderOption(option, optionClassName)
            )}
          </ul>
        )}
        {/* </div> */}
      </div>
    )
  }
}

export default SelectField
