import { useContext, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Headroom from 'react-headroom'
import { animated, useSpring } from 'react-spring/web.cjs'
import * as easings from 'd3-ease'

import {
  GlobalDispatchContext,
  GlobalStateContext,
} from 'context/GlobalContextProvider'
import { SitesContext } from 'context/SitesContextProvider'
import headerScrolled from 'actions/header/scrolled'
import useWindowSize from 'hooks/useWindowSize'

import Modal from 'components/Modal'
import Logo from 'components/Logo'
import Menu from 'components/Menu'
import MenuMobile from 'components/MenuMobile'
import WordpressLink from 'components/WordpressLink'
import MenuSearch from 'components/MenuSearch'
// todo: buttons should be moved into one component (or wrapper component)
import ButtonClose from 'components/ButtonClose'
import ButtonLocationMobile from 'components/ButtonLocationMobile'
import ButtonLocationDesktop from 'components/ButtonLocationDesktop'
import ButtonMenuMobile from 'components/ButtonMenuMobile'
import ButtonMenuDesktop from 'components/ButtonMenuDesktop'
import ButtonSearch from 'components/ButtonSearch'
import ButtonCollection from 'components/ButtonCollection'
import HardcodedFlashMessage from 'components/HardcodedFlashMessage'

import s from './Header.module.css'
import getCurrentSiteBusinessUnit from '../../helpers/getCurrentSiteBusinessUnit'

function Header({ globalSettings, className }) {
  const router = useRouter()
  const state = useContext(GlobalStateContext)
  const { flashMessageCleared } = state.header
  const dispatch = useContext(GlobalDispatchContext)
  const { sites, currentSite } = useContext(SitesContext)
  const [menuVisible, setMenuVisible] = useState(false)
  const [menuMobileVisible, setMenuMobileVisible] = useState(null)
  const [menu, setMenu] = useState(null)
  const [menuMobile, setMenuMobile] = useState(null)
  const [menuMobileTitle, setMenuMobileTitle] = useState('Select London')
  const [boardMenuVisible, setBoardMenuVisible] = useState(false)
  const isHeaderScrolled = state?.header?.scrolled
  const menusVisible = menuVisible || menuMobileVisible
  const windowSize = useWindowSize()
  const isDesktop = windowSize?.width >= 900
  const isMobile = windowSize?.width < 900
  const isHighlightPage = router.pathname === '/[site]/highlight/[slug]'
  const isScamPage =
    router.asPath === `/${currentSite?.name.toLowerCase()}/beware-of-scammers`
  const headerUiColour = 'var(--header-ui-color)'
  const isSingleSite = typeof sites !== 'undefined' && sites.length === 1

  const mainMenu = globalSettings?.mainMenu?.acfMenuItems?.menu?.[0].items
  const secMenu = globalSettings?.mainMenu?.acfMenuItems?.menu?.[1].items

  useEffect(() => {
    const doc = document.documentElement
    const dataHeaderMenuOpen = 'data-header-menu-open'
    if (menuVisible || menuMobileVisible) {
      doc.setAttribute(dataHeaderMenuOpen, true)
    } else if (!menuVisible || !menuMobileVisible) {
      doc.removeAttribute(dataHeaderMenuOpen)
    }
    const dataHeaderScrolled = 'data-header-scrolled'
    if (isHeaderScrolled) {
      doc.setAttribute(dataHeaderScrolled, true)
    } else if (!isHeaderScrolled) {
      doc.removeAttribute(dataHeaderScrolled)
    }
  }, [router, menuVisible, menuMobileVisible, isHeaderScrolled])

  useEffect(() => {
    if (!isMobile) setMenuMobileVisible(false)
  }, [isMobile])

  useEffect(() => {
    if (!isDesktop) setMenuVisible(false)
  }, [isDesktop])

  useEffect(() => {
    setMenuVisible(false)
    setMenuMobileVisible(false)
    setMenu(null)
    setMenuMobile(null)
    setBoardMenuVisible(false)
  }, [router])

  const handleHeaderScrolled = (scrolled) => {
    dispatch(headerScrolled(scrolled))
  }

  const handleOpenMenu = (type) => {
    setMenuVisible(true)
    setMenu(type)
  }

  const handleCloseMenu = () => {
    setMenuVisible(false)
    setMenuMobileVisible(false)
    setMenu(null)
    setMenuMobile(null)
    setMenuMobileTitle('Select London')
    setBoardMenuVisible(false)
  }

  const handleOpenMenuMobile = (type) => {
    setMenuMobileVisible(true)
    setMenuMobile(type)
    handleMenuTitle(`Select ${currentSite?.name}`)
  }

  const handleOpenSearch = () => {
    handleOpenMenuMobile('search')
    setMenuMobileTitle('Search by name')
  }

  const handleOpenBoardMenu = () => {
    setBoardMenuVisible(true)
  }

  const handleMenuTitle = (title) => {
    setMenuMobileTitle(title)
  }

  const handleMobileBackBtn = () => {
    handleOpenMenuMobile('main')
    setBoardMenuVisible(false)
  }

  // TODO: Need to update the logic for this from the href/as props.
  const handleBackBtn = () => {
    router.back()
    // if (document.referrer === '') {
    //   router.back()
    // } else {
    //   router.push('/[site]', currentSite?.path)
    // }
  }

  const sharedMobileMenuProps = {
    handleOpenSearch,
    handleMenuTitle,
    globalSettings,
  }

  const menuTransitionMobile = useSpring({
    opacity: menusVisible ? 1 : 0,
    config: { duration: 300, easing: easings.easeCubic },
  })

  const menuTransitionDesktop = useSpring({
    left: menusVisible ? '0%' : '-100% ',
    config: { duration: 300, easing: easings.easeCubic },
  })

  return (
    <div>
      {!isScamPage && !flashMessageCleared && <HardcodedFlashMessage />}
      <Headroom
        upTolerance={25}
        downTolerance={100}
        wrapperStyle={{
          position: 'relative',
          zIndex: '110',
          height: '0',
        }}
        style={{
          backgroundColor:
            menuVisible || menuMobileVisible ? 'transparent' : '#fff',
        }}
        onPin={() => handleHeaderScrolled(true)}
        onUnpin={() => handleHeaderScrolled(false)}
        onUnfix={() => handleHeaderScrolled(false)}
      >
        <div
          id="header"
          className={classNames(s.container, className, {
            [s.menuVisible]: menuVisible,
            [s.menuMobileVisible]: menuMobileVisible,
          })}
        >
          <div className={s.headerContent}>
            {/* <ButtonBack onClick={handleBackBtn} /> */}
            {isHighlightPage && !menuMobileVisible && (
              <button
                type="button"
                className={s.backBtn}
                onClick={handleBackBtn}
              />
            )}
            <ButtonMenuDesktop
              className={classNames({
                [s.menuBtnDesktopClose]: menuVisible && !menuMobileVisible,
              })}
              colour={headerUiColour}
              onClick={() => {
                if (menuVisible) {
                  handleOpenMenu('')
                  handleCloseMenu()
                } else {
                  handleOpenMenu('menu')
                }
              }}
            />

            {!process.env.NEXT_PUBLIC_DISABLE_COLLECTIONS_FEATURE && (
              <ButtonCollection colour={headerUiColour} />
            )}
            <ButtonSearch
              colour={headerUiColour}
              onClick={() => handleOpenMenu('search')}
            />

            {!router.isFallback && !isSingleSite && (
              <div>
                <ButtonLocationMobile
                  colour={headerUiColour}
                  onClick={() => handleOpenMenuMobile('locations')}
                />
                <ButtonLocationDesktop
                  colour={headerUiColour}
                  currentSite={currentSite?.name}
                  onClick={() => handleOpenMenu('locations')}
                />
              </div>
            )}
            {!menuVisible && !menuMobileVisible && (
              <ButtonMenuMobile
                colour={headerUiColour}
                onClick={() => handleOpenMenuMobile('main')}
              />
            )}
            {menuMobileVisible && (
              <>
                {!isSingleSite && (
                  <span className={s.mobileMenuTitle}>{menuMobileTitle}</span>
                )}
                {(boardMenuVisible || menuMobile === 'search') && (
                  <button
                    type="button"
                    className={s.backBtnMobile}
                    onClick={handleMobileBackBtn}
                  />
                )}
                <ButtonClose
                  colour={headerUiColour}
                  onClick={handleCloseMenu}
                />
              </>
            )}
            <div
              className={classNames(s.links, s.linksMain, {
                [s.linksMainVisible]: mainMenu && mainMenu.length > 0,
              })}
            >
              {mainMenu &&
                mainMenu
                  .filter((f) => f.link.uri !== '/')
                  .map((lProps) => (
                    <WordpressLink
                      key={`${currentSite?.path}_${lProps.link.uri}_${lProps.label}`}
                      {...lProps}
                      className={classNames(s.link, {
                        [s.linkActive]:
                          router.asPath ===
                          `/${router.query?.site}/${lProps.slug}`,
                      })}
                    />
                  ))}
            </div>
            {!menuMobileVisible && (
              <Logo
                businessUnit={getCurrentSiteBusinessUnit()}
                className={s.logo}
                href={currentSite?.path}
                colour="var(--header-logo-color)"
              />
            )}
            <Modal
              isShowing={menuVisible || menuMobileVisible}
              hide={handleCloseMenu}
              className={s.menu}
              closeClassName={s.menuCloseBtn}
              closeBtn
            >
              <div className={s.menuContainer}>
                <animated.div
                  className={s.menuWrapper}
                  style={
                    isDesktop ? menuTransitionDesktop : menuTransitionMobile
                  }
                >
                  {menuMobileVisible && menuMobile === 'main' && (
                    <MenuMobile
                      showChildMenu={boardMenuVisible}
                      handleOpenBoardMenu={handleOpenBoardMenu}
                      mainMenu={mainMenu}
                      secondaryMenu={secMenu}
                      {...sharedMobileMenuProps}
                    />
                  )}
                  {menuMobileVisible && menuMobile === 'search' && (
                    <MenuMobile search {...sharedMobileMenuProps} />
                  )}
                  {menuMobileVisible && menuMobile === 'locations' && (
                    <MenuMobile locations={sites} {...sharedMobileMenuProps} />
                  )}
                  {menu === 'menu' && (
                    <Menu mainLinks={mainMenu} secLinks={secMenu} />
                  )}
                  {menu === 'locations' && <Menu locations={sites} />}
                  {menu === 'search' && (
                    <MenuSearch globalSettings={globalSettings} />
                  )}
                </animated.div>
              </div>
            </Modal>
          </div>
        </div>
      </Headroom>
    </div>
  )
}

Header.propTypes = {
  globalSettings: PropTypes.object,
  className: PropTypes.string,
}
Header.defaultProps = {
  globalSettings: {},
  className: '',
}

export default Header
