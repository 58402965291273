import { useContext } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { SitesContext } from 'context/SitesContextProvider'

import ModelMeasurements from 'components/ModelMeasurements'
import CloudinarySolarnetImage from 'components/CloudinarySolarnetImage'
import AbbreviatedNumber from 'components/AbbreviatedNumber'
import Link from 'components/Link'

import s from './ModelListModel.module.css'

function ModelListModel({
  group,
  firstName,
  lastName,
  image,
  hideMeasurements,
  measurements,
  instagramCount,
  tiktok,
  tiktokCount,
  showFollowers,
  href,
  withBoard,
  className,
}) {
  const { currentSite } = useContext(SitesContext)

  const enableTikTok = parseInt(process.env.NEXT_PUBLIC_ENABLE_TIKTOK ?? '0', 10) === 1;
  const enableTikTokCount = parseInt(process.env.NEXT_PUBLIC_ENABLE_TIKTOK_COUNT ?? '0', 10) === 1;

  const setInstagram = (igClass) => {
    if (!instagramCount) return null
    return (
      <span
        className={classNames(s.instagram, igClass, {
          [s.instagramVisible]: showFollowers === true,
        })}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          className={s.instagramIcon}
        >
          <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
        </svg>
        <AbbreviatedNumber>{instagramCount}</AbbreviatedNumber>
      </span>
    )
  }

  const setTiktok = (tiktokClass) => {
    if (!tiktokCount) return null
    return (
      <span
        className={classNames(s.tiktok, tiktokClass, {
          [s.tiktokVisible]: showFollowers === true,
        })}
      >
        <svg
          width="18"
          height="20"
          viewBox="0 0 18 20"
          xmlns="http://www.w3.org/2000/svg"
          className={s.instagramIcon}
        >
          <path d="M17.8811 8.5693V5.06663C16.2408 5.06663 14.9889 4.6288 14.1811 3.7778C13.352 2.80658 12.8875 1.57664 12.8676 0.299797V0.0469636L9.5561 -0.0332031C9.5561 -0.0332031 9.5561 0.10863 9.5561 0.299797V14.1933C9.4425 14.814 9.14119 15.3849 8.69285 15.829C8.24451 16.273 7.67071 16.5688 7.04894 16.6764C6.42716 16.784 5.78733 16.6983 5.21584 16.4307C4.64434 16.1632 4.1687 15.7267 3.85311 15.1803C3.53752 14.6339 3.39719 14.0037 3.45106 13.375C3.50492 12.7463 3.7504 12.1493 4.15434 11.6645C4.55828 11.1797 5.10124 10.8305 5.70992 10.6641C6.3186 10.4977 6.96369 10.522 7.5581 10.7338V7.34213C7.21152 7.28534 6.86095 7.25647 6.50976 7.2558C5.2462 7.2558 4.01102 7.63049 2.96041 8.33248C1.9098 9.03448 1.09095 10.0323 0.607405 11.1996C0.123862 12.367 -0.00265501 13.6516 0.243853 14.8908C0.490361 16.1301 1.09882 17.2685 1.99229 18.1619C2.88576 19.0554 4.02412 19.6639 5.2634 19.9104C6.50268 20.1569 7.78722 20.0304 8.9546 19.5468C10.122 19.0633 11.1198 18.2444 11.8217 17.1938C12.5237 16.1432 12.8984 14.908 12.8984 13.6445C12.8977 13.3827 12.8813 13.1211 12.8491 12.8613V7.2188C14.3549 8.15918 16.1068 8.62936 17.8811 8.5693Z" />
        </svg>
        {tiktok}
        {enableTikTokCount && <><span>&nbsp;</span><AbbreviatedNumber>{tiktokCount}</AbbreviatedNumber></>}
      </span>
    )
  }

  return (
    <Link
      className={classNames(s.container, className)}
      href={href}
      type={withBoard ? 'BoardModel' : 'Model'}
    >
      {image && (
        <div className={s.imgWrap}>
          <CloudinarySolarnetImage
            className={s.img}
            image={[
              {
                url: image.sourceUrl,
                params: 'w_400,ar_3:4,c_fit,g_north,f_auto',
                alt: `${firstName} ${lastName}`,
              },
            ]}
          />
          {!hideMeasurements && (
            <div className={s.overlay}>
              {group !== 'talent' && (
                <ModelMeasurements
                  className={s.measurements}
                  listClassName={s.measurementsList}
                  itemClassName={s.measurement}
                  valueClassName={s.measurementValue}
                  measurements={measurements}
                  units={currentSite?.units}
                  shoeSizeUnits={currentSite?.shoeSizeUnits}
                  male={group === 'men'}
                  noToggle
                />
              )}
              {setInstagram()}
              {enableTikTok && setTiktok()}
            </div>
          )}
        </div>
      )}
      {firstName && (
        <span
          className={classNames(s.name, {
            [s.nameNoPadding]: hideMeasurements === true,
          })}
        >
          {firstName}
        </span>
      )}
      {lastName && lastName !== '0' && (
        <span
          className={classNames(s.name, {
            [s.nameNoPadding]: hideMeasurements === true,
          })}
        >
          {lastName}
        </span>
      )}
      {setInstagram(s.instagramGrid)}
      {setTiktok(s.instagramGrid)}
    </Link>
  )
}

ModelListModel.propTypes = {
  group: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  image: PropTypes.object,
  hideMeasurements: PropTypes.bool,
  measurements: PropTypes.array,
  instagramCount: PropTypes.number,
  tiktok: PropTypes.string,
  tiktokCount: PropTypes.number,
  href: PropTypes.string,
  withBoard: PropTypes.bool,
  showFollowers: PropTypes.bool,
}
ModelListModel.defaultProps = {
  group: '',
  firstName: '',
  lastName: '',
  image: {},
  hideMeasurements: false,
  measurements: [],
  instagramCount: 0,
  tiktok: null,
  tiktokCount: 0,
  href: '',
  withBoard: false,
  showFollowers: false,
}

export default ModelListModel
